import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";

const Login = () => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const navigate = useNavigate("");
  return (
    <div
      style={{
        maxWidth: "100%",
        maxHeight: "100%",
        minHeight: "100%",
        minWidth: "100%",
        backgroundImage: "inherit",
      }}
    >
      <form>
        <Box
          sx={{ boxSizing: "content-box" }}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Card
            sx={{
              marginTop: "5%",
            }}
            raised
          >
            <Box
              display="flex"
              justifyItems="center"
              flexDirection={"column"}
              alignItems="center"
              width={450}
              height={550}
            >
              <img
                src="image/logo1.png"
                width={200}
                alt=""
                sx={{ paddingTop: 10 }}
              ></img>
              <br />
              <Typography
                variant="body2"
                textAlign="center"
                fontFamily={"Arial"}
              >
                Vous avez déja un compte? veuillez se connecter
              </Typography>
              <FormControl
                sx={{ m: 1, width: "40ch", marginTop: 3 }}
                variant="outlined"
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Adresse email
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="email"
                  required
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                      >
                        <AlternateEmailIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Adresse email"
                />
              </FormControl>
              <FormControl sx={{ m: 1, width: "40ch" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Mot de passe
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Mot de passe"
                />
              </FormControl>
              <Button
                onClick={() => navigate("/side-nav")}
                variant="contained"
                sx={{ m: 1, height: "6ch", width: "44ch" }}
                type="submit"
              >
                Se Connecter
              </Button>
              <br />
              <Typography variant="body2" textAlign="center">
                <a href="/" style={{ textDecoration: "none" }}>
                  Mot de passe oublié?
                </a>
              </Typography>
              <br />
              <Typography variant="body2" textAlign="center">
                <Link to="/registration" style={{ textDecoration: "none" }}>
                  S'inscrire
                </Link>
              </Typography>
              <br />
            </Box>
          </Card>
        </Box>
      </form>
    </div>
  );
};

export default Login;
