import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import FacebookIcon from "@mui/icons-material/Facebook";
import SendIcon from "@mui/icons-material/Send";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EmailIcon from "@mui/icons-material/Email";
import CallIcon from "@mui/icons-material/Call";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Button,
  CardActionArea,
  CardMedia,
  TextField,
} from "@mui/material";
import { LinkedIn } from "@mui/icons-material";
import { Link } from "react-router-dom";

function LandingPage() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };
  const navigate = useNavigate("");

  return (
    <>
      <AppBar position="fixed">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <img
              src="image/logo3.png"
              alt=""
              style={{
                width: "10ch",
                display: { xs: "none", md: "flex" },
                mr: 1,
              }}
            />
            <Box
              sx={{
                flexGrow: 1,
                display: { xs: "flex", md: "none" },
                justifyContent: { xs: "flex-end", md: "none" },
              }}
              alignItems="flex-end"
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem>
                  <Typography textAlign="center">Accueil</Typography>
                </MenuItem>
                <MenuItem>
                  <Typography textAlign="center">Nos Offres</Typography>
                </MenuItem>
                <MenuItem>
                  <Typography textAlign="center">A propos</Typography>
                </MenuItem>
                <MenuItem onClick={() => navigate("/login")}>
                  <Typography textAlign="center">Se connecter</Typography>
                </MenuItem>
              </Menu>
            </Box>

            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Accueil
              </Button>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Nos Offres
              </Button>
              <Button
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                A propos
              </Button>
              <Button
                onClick={() => navigate("/login")}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                Se connecter
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box>
        <Box
          container
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
            padding: "10px",
            alignItems: "flex-start",
            gap: "10px",
          }}
          maxHeight="xs"
        >
          <img
            src="image/desktop.jpg"
            alt=""
            style={{ width: "100%", height: "100%", margin: 0 }}
          />
        </Box>
        <Container akli="center">
          <Grid container spacing={0.5}>
            <Grid item xs={6} sm={4} ms={4} lg={3}>
              <Card display="flex" sx={{ maxWidth: 250, marginTop: -13 }}>
                <CardContent>
                  <Typography textAlign="center" variant="h5" component="div">
                    <img
                      src="image/thinking.png"
                      alt=""
                      style={{ width: "4ch" }}
                    />
                  </Typography>

                  <Typography textAlign="center" variant="h5" component="div">
                    Compétence
                  </Typography>
                  <Typography textAlign="center" variant="body2">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={4} ms={4} lg={3}>
              <Card sx={{ maxWidth: 250, marginTop: -13 }}>
                <CardContent>
                  <Typography textAlign="center" variant="h5" component="div">
                    <img
                      src="image/productivity.png"
                      alt=""
                      style={{ width: "4ch" }}
                    />
                  </Typography>

                  <Typography textAlign="center" variant="h5" component="div">
                    Productivité
                  </Typography>
                  <Typography textAlign="center" variant="body2">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={4} ms={4} lg={3}>
              <Card sx={{ maxWidth: 250, marginTop: -13 }}>
                <CardContent>
                  <Typography textAlign="center" variant="h5" component="div">
                    <img src="image/deal.png" alt="" style={{ width: "4ch" }} />
                  </Typography>

                  <Typography textAlign="center" variant="h5" component="div">
                    Collaboration
                  </Typography>
                  <Typography textAlign="center" variant="body2">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={6} sm={4} ms={4} lg={3}>
              <Card sx={{ maxWidth: 250, marginTop: -13 }}>
                <CardContent>
                  <Typography textAlign="center" variant="h5" component="div">
                    <img
                      src="image/leadership.png"
                      alt=""
                      style={{ width: "4ch" }}
                    />
                  </Typography>

                  <Typography textAlign="center" variant="h5" component="div">
                    Leadership
                  </Typography>
                  <Typography textAlign="center" variant="body2">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry.
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <br />
        <hr></hr>
        <br />
        <Typography variant="h5" textAlign="center">
          Nos offres
        </Typography>
        <Typography variant="body1" textAlign="center">
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s,
        </Typography>
        <br />
        <Container>
          <Grid justifyContent="center" container spacing={2}>
            <Grid item xs={12} sm={6} lg={4}>
              <Card sx={{ width: 375 }} raised>
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image="image/logo1.png"
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      textAlign="center"
                    >
                      Basic
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      0 Ar/mois
                      <br />
                      <br />
                      Géstion employé
                      <br />
                      <del>Gestion retard</del>
                      <br />
                      <del>Gestion performance</del>
                      <br />
                      <del>Création test d'évaluation</del>
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button size="small" variant="contained" alignItems="center">
                    Commander
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Card sx={{ width: 375 }} raised alignItems="center">
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image="image/logo2.png"
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      Pro
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      5 000 Ar/mois
                      <br />
                      <br />
                      Géstion employé
                      <br />
                      Gestion retard
                      <br />
                      <del>Gestion performance</del>
                      <br />
                      <del>Création test d'évaluation</del>
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions justifyContent="center">
                  <Button size="small" variant="contained" color="success">
                    Commander
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} lg={4}>
              <Card sx={{ width: 375 }} raised alignItems="center">
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image="image/logo3.jpg"
                    alt="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h6" component="div">
                      Premium
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      10 0000 Ar/mois
                      <br />
                      <br />
                      Géstion employé
                      <br />
                      Gestion retard
                      <br />
                      Gestion performance
                      <br />
                      Création test d'évaluation
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions justifyContent="center">
                  <Button
                    size="small"
                    sx={{ background: "#000" }}
                    variant="contained"
                  >
                    Commander
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>

        <br />
        <hr></hr>
        <Typography variant="h5" textAlign="center">
          A propos
        </Typography>
        <br></br>
        <Grid
          sx={{ marginLeft: 1.5 }}
          container
          justifyContent="center"
          spacing={3}
        >
          <Grid justifyContent="center" item xs={12} lg={6}>
            <img
              src="image/undraw_Login_re_4vu2.png"
              style={{ width: "50%" }}
              alt="bg_apropos"
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h6">Eval pro:</Typography>
            <Typography variant="body1">
              C'est une plateforme de géstion et de traitement de performance
              des employé dans un entreprise. Cette plateforme a été créer par
              haisoa
            </Typography>
            <br />
            <Typography variant="h6">Objectif:</Typography>
            <Typography variant="body1">
              Elle a été concu pour faciliter les tâches de reponsable de
              ressource humaine prendre de décision par exemple licenciement des
              employés ou méne a une recrutement d'un ou plusieurs employés
            </Typography>
          </Grid>
        </Grid>
        <br />
        <hr></hr>
        <Typography variant="h5" textAlign="center">
          Contact
        </Typography>
        <br></br>
        <Grid paddingLeft={3} container justifyContent="center" spacing={3}>
          <Grid justifyContent="center" item xs={12} lg={6}>
            <Typography variant="h6">Suivez nous:</Typography>
            <Typography variant="body1">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              ind Lorem Ipsum has been the industt standard dummy text ever
              since t
            </Typography>
            <FacebookIcon />
            <LinkedIn />
          </Grid>
          <Grid
            display="flex"
            flexDirection="column"
            justifyContent="center"
            item
            xs={12}
            lg={6}
            spacing={2}
          >
            <TextField id="outlined-required" label="Nom" type="test" />
            <TextField
              id="outlined-required"
              sx={{ marginTop: 1.5 }}
              label="Email"
              type="email"
            />
            <TextField
              id="outlined-required"
              sx={{ marginTop: 1.5 }}
              label="Objet"
              type="text"
            />
            <TextField
              id="outlined-required"
              multiline
              label="Message"
              type="text"
              rows={4}
              sx={{ marginTop: 1.5 }}
            />
            <br />
            <Button
              variant="contained"
              sx={{ width: 115 }}
              endIcon={<SendIcon />}
            >
              Envoyer
            </Button>
          </Grid>
        </Grid>
      </Box>

      <br></br>
      <br></br>

      <Grid
        spacing={3}
        display="flex"
        flexDirection="row"
        justifyContent="center"
        item
        container
        sx={{
          backgroundColor: "#3777CE",
        }}
      >
        <Grid item md={2.4} justifyContent="center">
          <img
            src="image/logo3.png"
            alt=""
            style={{
              width: "10ch",
              display: { xs: "none", md: "flex" },
              mr: 1,
            }}
          />
        </Grid>
        <Grid item md={2.4} justifyContent="center">
          <Typography color="white" variant="body2">
            <LocationOnIcon />
            Lot VK 17A Fenomanana-Mahazoarivo Antananarivo-101 Madagascar
          </Typography>
          <Typography color="white" variant="body2">
            <EmailIcon />
            antsarazaka@gmail.com
          </Typography>
          <Typography color="white" variant="body2">
            <CallIcon />
            +261345534014
          </Typography>
        </Grid>
        <Grid item md={2.4} justifyContent="center"></Grid>
        <Grid item md={2.4} justifyContent="center"></Grid>
        <Grid item md={2.4} justifyContent="center"></Grid>
      </Grid>
      <Box
        sx={{
          backgroundColor: "#0F66CC",
        }}
      >
        <Typography textAlign="center" color="white" variant="body2">
          ⓒ Copyright HAISOA 2023. Tous droits réservés. &nbsp;
          <Link color="inherit" to="https://haisoa.com" underline="always">
            Haisoa
          </Link>
        </Typography>

        <Link to="/front-office">Front Office</Link>
      </Box>
    </>
  );
}
export default LandingPage;
