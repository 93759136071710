import React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

const InscriptionResponsable = () => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <>
      <div>
        <form>
          <Box
            sx={{ boxSizing: "content-box" }}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Card
              sx={{
                marginTop: "5%",
              }}
              raised
            >
              <Box
                display="flex"
                justifyItems="center"
                flexDirection={"column"}
                alignItems="center"
                width={400}
                height={550}
              >
                <img src="image/logo1.png" width={200} alt=""></img>
                <Typography variant="body2" gutterBottom fontFamily={"Arial"}>
                  Veuillez remplir cette formulaire d'inscription
                </Typography>
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={role}
                  sx={{ width: 345, m: 1 }}
                  renderInput={(params) => (
                    <TextField {...params} label="S'inscrire en tant que" />
                  )}
                />
                <FormControl sx={{ m: 1, width: "40ch" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Adresse email
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type="email"
                    required
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <AlternateEmailIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Adresse email"
                  />
                </FormControl>

                <FormControl sx={{ m: 1, width: "40ch" }} variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Mot de passe
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Mot de passe"
                  />
                </FormControl>
                <Button
                  variant="contained"
                  sx={{ m: 1, height: "6ch", width: "44ch" }}
                >
                  S'inscrire
                </Button>
                <Typography variant="body2">
                  <a href="/" style={{ textDecoration: "none" }}>
                    Retour
                  </a>
                </Typography>
                <br />
              </Box>
            </Card>
          </Box>
        </form>
      </div>
    </>
  );
};
const role = [{ label: "Administrateur" }, { label: "Simple utilisateur" }];

export default InscriptionResponsable;
