import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/material";
const PerformanceManagement = () => {
  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "nom", headerName: "Nom", width: 200 },
    {
      field: "prenom",
      headerName: "Prénom",
      width: 130,
    },
    { field: "critere", headerName: "Critère", width: 130 },
    {
      field: "note",
      headerName: "Note",
      width: 90,
    },
  ];
  const rows = [
    {
      id: 1,
      nom: "cluckham0",
      prenom: "lblackwell0",
      note: "image/gif",
      critere: "video/mpeg",
    },
    {
      id: 2,
      nom: "mgerring1",
      prenom: "bpratten1",
      note: "application/excel",
      critere: "video/mpeg",
    },
    {
      id: 3,
      nom: "eredman2",
      prenom: "mpopley2",
      note: "video/x-msvideo",
      critere: "application/x-msexcel",
    },
    {
      id: 4,
      nom: "lwyburn3",
      prenom: "tcordery3",
      note: "application/mspowerpoint",
      critere: "image/x-tiff",
    },
    {
      id: 5,
      nom: "eessel4",
      prenom: "mace4",
      note: "image/x-tiff",
      critere: "application/x-troff-msvideo",
    },
    {
      id: 6,
      nom: "ourwin5",
      prenom: "gjanning5",
      note: "video/msvideo",
      critere: "image/png",
    },
    {
      id: 7,
      nom: "bfirby6",
      prenom: "bbellows6",
      note: "application/msword",
      critere: "video/quicktime",
    },
    {
      id: 8,
      nom: "schittey7",
      prenom: "bpossa7",
      note: "image/png",
      critere: "audio/x-mpeg-3",
    },
    {
      id: 9,
      nom: "asherman8",
      prenom: "mmcgrah8",
      note: "video/x-mpeg",
      critere: "audio/mpeg3",
    },
    {
      id: 10,
      nom: "gyorath9",
      prenom: "jarnould9",
      note: "application/pdf",
      critere: "application/x-excel",
    },
    {
      id: 11,
      nom: "jsercombea",
      prenom: "hdeeminga",
      note: "application/x-troff-msvideo",
      critere: "application/x-excel",
    },
    {
      id: 12,
      nom: "bbeatenb",
      prenom: "lbeetonb",
      note: "video/mpeg",
      critere: "application/pdf",
    },
    {
      id: 13,
      nom: "osketc",
      prenom: "rbalogunc",
      note: "text/plain",
      critere: "application/x-excel",
    },
    {
      id: 14,
      nom: "dchasmord",
      prenom: "dcallowayd",
      note: "video/mpeg",
      critere: "application/vnd.ms-powerpoint",
    },
    {
      id: 15,
      nom: "ccapnere",
      prenom: "nsnele",
      note: "image/jpeg",
      critere: "application/mspowerpoint",
    },
    {
      id: 16,
      nom: "lmcblainf",
      prenom: "jarpef",
      note: "image/png",
      critere: "application/vnd.ms-powerpoint",
    },
    {
      id: 17,
      nom: "rdedmamg",
      prenom: "bgimsong",
      note: "image/pjpeg",
      critere: "application/x-excel",
    },
    {
      id: 18,
      nom: "ajoslowh",
      prenom: "lturnbullh",
      note: "application/pdf",
      critere: "application/vnd.ms-powerpoint",
    },
    {
      id: 19,
      nom: "cbeaconsalli",
      prenom: "candreevi",
      note: "video/x-msvideo",
      critere: "application/x-troff-msvideo",
    },
    {
      id: 20,
      nom: "rsabatej",
      prenom: "csambrookj",
      note: "video/mpeg",
      critere: "video/x-mpeg",
    },
    {
      id: 21,
      nom: "jcaldwallk",
      prenom: "kbellhousek",
      note: "image/pjpeg",
      critere: "application/msword",
    },
    {
      id: 22,
      nom: "ahibbartl",
      prenom: "kshackladyl",
      note: "video/avi",
      critere: "application/vnd.ms-excel",
    },
    {
      id: 23,
      nom: "hfairweatherm",
      prenom: "wglidderm",
      note: "video/quicktime",
      critere: "application/x-excel",
    },
    {
      id: 24,
      nom: "hunderwoodn",
      prenom: "lhorrelln",
      note: "image/x-tiff",
      critere: "application/x-mspowerpoint",
    },
    {
      id: 25,
      nom: "wdrivero",
      prenom: "tdodso",
      note: "application/excel",
      critere: "video/quicktime",
    },
    {
      id: 26,
      nom: "ptidridgep",
      prenom: "nalflatp",
      note: "image/png",
      critere: "application/x-troff-msvideo",
    },
    {
      id: 27,
      nom: "ldictyq",
      prenom: "efinlanq",
      note: "application/x-mspowerpoint",
      critere: "application/x-mspowerpoint",
    },
    {
      id: 28,
      nom: "lkleinmannr",
      prenom: "fmordeyr",
      note: "application/vnd.ms-excel",
      critere: "application/excel",
    },
    {
      id: 29,
      nom: "jellisons",
      prenom: "maccombs",
      note: "application/powerpoint",
      critere: "application/excel",
    },
    {
      id: 30,
      nom: "mbellast",
      prenom: "cweekest",
      note: "application/x-troff-msvideo",
      critere: "image/png",
    },
    {
      id: 31,
      nom: "jjakucewiczu",
      prenom: "ltucku",
      note: "video/quicktime",
      critere: "video/quicktime",
    },
    {
      id: 32,
      nom: "mdiceyv",
      prenom: "ahurllv",
      note: "video/x-msvideo",
      critere: "video/mpeg",
    },
    {
      id: 33,
      nom: "gtenmanw",
      prenom: "tmetzigw",
      note: "application/x-excel",
      critere: "application/pdf",
    },
    {
      id: 34,
      nom: "mhessex",
      prenom: "ctalbotx",
      note: "video/x-mpeg",
      critere: "image/jpeg",
    },
    {
      id: 35,
      nom: "sblakeleyy",
      prenom: "deilhermanny",
      note: "image/pjpeg",
      critere: "application/x-excel",
    },
    {
      id: 36,
      nom: "mcarlawz",
      prenom: "arhodusz",
      note: "image/x-tiff",
      critere: "application/mspowerpoint",
    },
    {
      id: 37,
      nom: "kpowelee10",
      prenom: "dswepstone10",
      note: "video/mpeg",
      critere: "video/avi",
    },
    {
      id: 38,
      nom: "kjendrusch11",
      prenom: "tnapton11",
      note: "video/mpeg",
      critere: "image/pjpeg",
    },
    {
      id: 39,
      nom: "kstendall12",
      prenom: "oproschek12",
      note: "video/x-msvideo",
      critere: "text/plain",
    },
    {
      id: 40,
      nom: "aphalp13",
      prenom: "pdelorenzo13",
      note: "application/vnd.ms-powerpoint",
      critere: "application/pdf",
    },
    {
      id: 41,
      nom: "ckulis14",
      prenom: "ebinnion14",
      note: "text/plain",
      critere: "image/jpeg",
    },
    {
      id: 42,
      nom: "rreinhardt15",
      prenom: "ederrell15",
      note: "audio/mpeg3",
      critere: "video/quicktime",
    },
    {
      id: 43,
      nom: "jprettyjohn16",
      prenom: "ewickins16",
      note: "video/quicktime",
      critere: "application/vnd.ms-excel",
    },
    {
      id: 44,
      nom: "ddispencer17",
      prenom: "bnelm17",
      note: "text/plain",
      critere: "application/excel",
    },
    {
      id: 45,
      nom: "grobberecht18",
      prenom: "jwragge18",
      note: "application/x-msexcel",
      critere: "image/gif",
    },
    {
      id: 46,
      nom: "apatis19",
      prenom: "tlutwidge19",
      note: "video/quicktime",
      critere: "video/quicktime",
    },
    {
      id: 47,
      nom: "ckuhwald1a",
      prenom: "aegdal1a",
      note: "application/vnd.ms-powerpoint",
      critere: "application/excel",
    },
    {
      id: 48,
      nom: "rziemsen1b",
      prenom: "dfordham1b",
      note: "application/pdf",
      critere: "application/mspowerpoint",
    },
    {
      id: 49,
      nom: "ogainsborough1c",
      prenom: "rmenezes1c",
      note: "application/excel",
      critere: "image/x-tiff",
    },
    {
      id: 50,
      nom: "jmurkin1d",
      prenom: "kfilip1d",
      note: "application/vnd.ms-excel",
      critere: "application/x-excel",
    },
    {
      id: 51,
      nom: "cfrounks1e",
      prenom: "vmyhill1e",
      note: "image/x-tiff",
      critere: "application/vnd.ms-excel",
    },
    {
      id: 52,
      nom: "bfalls1f",
      prenom: "bhakes1f",
      note: "application/vnd.ms-powerpoint",
      critere: "text/plain",
    },
    {
      id: 53,
      nom: "tpergens1g",
      prenom: "ekettlesting1g",
      note: "application/msword",
      critere: "text/plain",
    },
    {
      id: 54,
      nom: "grubury1h",
      prenom: "mperris1h",
      note: "image/png",
      critere: "image/x-tiff",
    },
    {
      id: 55,
      nom: "tesgate1i",
      prenom: "cimpey1i",
      note: "text/plain",
      critere: "application/x-mspowerpoint",
    },
    {
      id: 56,
      nom: "gantonetti1j",
      prenom: "ddipple1j",
      note: "image/pjpeg",
      critere: "image/tiff",
    },
    {
      id: 57,
      nom: "kfleote1k",
      prenom: "ablackaller1k",
      note: "video/mpeg",
      critere: "image/jpeg",
    },
    {
      id: 58,
      nom: "lmahedy1l",
      prenom: "nstanfield1l",
      note: "application/powerpoint",
      critere: "image/x-tiff",
    },
    {
      id: 59,
      nom: "sconnew1m",
      prenom: "hpregel1m",
      note: "application/msword",
      critere: "video/quicktime",
    },
    {
      id: 60,
      nom: "rcastillo1n",
      prenom: "gplume1n",
      note: "audio/x-mpeg-3",
      critere: "image/x-tiff",
    },
    {
      id: 61,
      nom: "bpaddefield1o",
      prenom: "bheiner1o",
      note: "application/vnd.ms-excel",
      critere: "application/vnd.ms-excel",
    },
    {
      id: 62,
      nom: "mtulleth1p",
      prenom: "aknewstub1p",
      note: "application/x-msexcel",
      critere: "application/vnd.ms-excel",
    },
    {
      id: 63,
      nom: "phaslin1q",
      prenom: "fgehring1q",
      note: "application/pdf",
      critere: "application/x-excel",
    },
    {
      id: 64,
      nom: "ahuttley1r",
      prenom: "wcreasey1r",
      note: "application/vnd.ms-powerpoint",
      critere: "application/pdf",
    },
    {
      id: 65,
      nom: "znevill1s",
      prenom: "fevitts1s",
      note: "audio/mpeg3",
      critere: "application/mspowerpoint",
    },
    {
      id: 66,
      nom: "vmacneice1t",
      prenom: "mpratton1t",
      note: "video/msvideo",
      critere: "application/excel",
    },
    {
      id: 67,
      nom: "ivinsen1u",
      prenom: "cforrest1u",
      note: "text/plain",
      critere: "video/mpeg",
    },
    {
      id: 68,
      nom: "chuleatt1v",
      prenom: "djeskin1v",
      note: "audio/mpeg3",
      critere: "video/avi",
    },
    {
      id: 69,
      nom: "jvolkes1w",
      prenom: "flantoph1w",
      note: "image/gif",
      critere: "video/msvideo",
    },
    {
      id: 70,
      nom: "iskipsea1x",
      prenom: "mdebischof1x",
      note: "application/powerpoint",
      critere: "video/msvideo",
    },
    {
      id: 71,
      nom: "afenlon1y",
      prenom: "amattschas1y",
      note: "application/excel",
      critere: "audio/x-mpeg-3",
    },
    {
      id: 72,
      nom: "zvandaalen1z",
      prenom: "smcilrath1z",
      note: "audio/mpeg3",
      critere: "application/powerpoint",
    },
    {
      id: 73,
      nom: "hcleveley20",
      prenom: "ehawkins20",
      note: "application/powerpoint",
      critere: "video/quicktime",
    },
    {
      id: 74,
      nom: "jdurnford21",
      prenom: "mraiker21",
      note: "image/jpeg",
      critere: "image/tiff",
    },
    {
      id: 75,
      nom: "yhruska22",
      prenom: "ssurmanwells22",
      note: "image/pjpeg",
      critere: "video/x-msvideo",
    },
    {
      id: 76,
      nom: "otrevascus23",
      prenom: "blepper23",
      note: "video/x-msvideo",
      critere: "application/msword",
    },
    {
      id: 77,
      nom: "tcarstairs24",
      prenom: "jcooper24",
      note: "audio/mpeg3",
      critere: "application/x-mspowerpoint",
    },
    {
      id: 78,
      nom: "agentil25",
      prenom: "ajimmison25",
      note: "application/excel",
      critere: "video/avi",
    },
    {
      id: 79,
      nom: "amcaster26",
      prenom: "nmoules26",
      note: "application/powerpoint",
      critere: "application/powerpoint",
    },
    {
      id: 80,
      nom: "kstradling27",
      prenom: "ocollumbell27",
      note: "application/x-troff-msvideo",
      critere: "image/pjpeg",
    },
    {
      id: 81,
      nom: "aduiged28",
      prenom: "rmcnulty28",
      note: "video/msvideo",
      critere: "image/png",
    },
    {
      id: 82,
      nom: "hflowerden29",
      prenom: "lreary29",
      note: "application/pdf",
      critere: "image/pjpeg",
    },
    {
      id: 83,
      nom: "shelbeck2a",
      prenom: "mmanlow2a",
      note: "application/vnd.ms-powerpoint",
      critere: "application/excel",
    },
    {
      id: 84,
      nom: "vduthy2b",
      prenom: "rtuckie2b",
      note: "application/x-troff-msvideo",
      critere: "application/x-excel",
    },
    {
      id: 85,
      nom: "nstittle2c",
      prenom: "rjochens2c",
      note: "application/vnd.ms-excel",
      critere: "application/x-troff-msvideo",
    },
    {
      id: 86,
      nom: "fskevington2d",
      prenom: "bhanbidge2d",
      note: "video/mpeg",
      critere: "audio/x-mpeg-3",
    },
    {
      id: 87,
      nom: "dfrankcomb2e",
      prenom: "llequeux2e",
      note: "application/vnd.ms-excel",
      critere: "video/msvideo",
    },
    {
      id: 88,
      nom: "bblazevic2f",
      prenom: "grollings2f",
      note: "audio/mpeg3",
      critere: "application/mspowerpoint",
    },
    {
      id: 89,
      nom: "dgilliatt2g",
      prenom: "cfennell2g",
      note: "application/msword",
      critere: "video/mpeg",
    },
    {
      id: 90,
      nom: "icarrivick2h",
      prenom: "sskelly2h",
      note: "application/msword",
      critere: "video/quicktime",
    },
    {
      id: 91,
      nom: "ztattershaw2i",
      prenom: "dpeters2i",
      note: "application/mspowerpoint",
      critere: "video/x-msvideo",
    },
    {
      id: 92,
      nom: "jkettlesting2j",
      prenom: "lpate2j",
      note: "audio/x-mpeg-3",
      critere: "video/x-msvideo",
    },
    {
      id: 93,
      nom: "fguarnier2k",
      prenom: "akentish2k",
      note: "image/png",
      critere: "image/tiff",
    },
    {
      id: 94,
      nom: "lhardson2l",
      prenom: "kblatherwick2l",
      note: "image/pjpeg",
      critere: "image/tiff",
    },
    {
      id: 95,
      nom: "gparadise2m",
      prenom: "atumini2m",
      note: "video/x-msvideo",
      critere: "application/excel",
    },
    {
      id: 96,
      nom: "lanscombe2n",
      prenom: "rbenger2n",
      note: "image/jpeg",
      critere: "application/mspowerpoint",
    },
    {
      id: 97,
      nom: "lboerder2o",
      prenom: "spallent2o",
      note: "application/msword",
      critere: "application/powerpoint",
    },
    {
      id: 98,
      nom: "glawrenson2p",
      prenom: "browberry2p",
      note: "application/vnd.ms-excel",
      critere: "video/mpeg",
    },
    {
      id: 99,
      nom: "cleggis2q",
      prenom: "akeough2q",
      note: "text/plain",
      critere: "image/tiff",
    },
    {
      id: 100,
      nom: "kpopelay2r",
      prenom: "gespadater2r",
      note: "application/vnd.ms-excel",
      critere: "application/msword",
    },
  ];
  return (
    <div>
      <Container>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 15]}
          checkboxSelection
        />
      </Container>
    </div>
  );
};

export default PerformanceManagement;
