import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Card, CardActions, CardContent, Container, Grid } from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
const drawerWidth = 240;
const navItems = ["TEST", "A PROPOS", "PROFIL", "CONTACT"];

function PasserTest(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <img
          src="image/logo1.png"
          alt=""
          style={{
            width: "10ch",
            display: { xs: "none", md: "flex" },
            mr: 1,
          }}
        />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const data_static = [
    {
      id: 1,
      question: "Qui a découvrez Madagascar?",
      reponse1: "Diégo Diaz",
      reponse2: "Marco Polo",
      reponse3: "Marcus RashFord",
      reponse4: "Stratus James",
    },
    {
      id: 2,
      question: "Qu'est ce que le mot leadership?",
      reponse1: "Footballeur",
      reponse2: "Tennis",
      reponse3: "Ecrivain",
      reponse4: "Femme",
    },
  ];

  const pageSize = 1; // Number of items per page
  const [currentPage, setCurrentPage] = React.useState(1);
  // Calculate the start and end indices for the current page
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;

  // Get the slice of data for the current page
  // const currentPageData = data_static.slice(startIndex, endIndex);

  const nextPage = () => {
    if (endIndex < data_static.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  const [progress, setProgress] = React.useState(5);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 5 : prevProgress + 5
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <img
              src="image/logo3.png"
              alt=""
              style={{
                width: "7ch",
                display: { xs: "none", md: "flex" },
                mr: 1,
              }}
            />
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: "#fff" }}>
                {item}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box
        component="main"
        sx={{ p: 3, width: "100%" }}
        justifyContent="center"
      >
        <Toolbar />
        <Box>
          <LinearProgressWithLabel value={progress} />
        </Box>
        <Container>
          <Grid container alignItems="center" spacing={1.5}>
            {data_static.slice(0, pageSize).map((item) => (
              <Grid key={item.id} item lg={12} xs={12}>
                <Card sx={{ width: "500px" }}>
                  <CardContent>
                    <Typography variant="h6">
                      <u>Question 1</u>
                      <br></br>
                      {item.question}
                    </Typography>
                    <br></br>
                    <Typography variant="body1"></Typography>
                    <FormGroup>
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label={item.reponse1}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={item.reponse2}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={item.reponse3}
                      />
                      <FormControlLabel
                        control={<Checkbox />}
                        label={item.reponse4}
                      />
                    </FormGroup>
                  </CardContent>
                  <CardActions
                    display="flex"
                    sx={{ justifyContent: "flex-end" }}
                  >
                    <Button
                      variant="contained"
                      onClick={prevPage}
                      disabled={currentPage === 1}
                    >
                      Previous Page
                    </Button>
                    <Button
                      onClick={nextPage}
                      disabled={endIndex >= data_static.length}
                    >
                      Next Page
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </Box>
  );
}

PasserTest.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default PasserTest;
