import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Card, CardActions, CardContent, Grid } from "@mui/material";

const drawerWidth = 240;
const navItems = ["TEST", "A PROPOS", "PROFIL", "CONTACT"];

function TestFront(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        <img
          src="image/logo1.png"
          alt=""
          style={{
            width: "10ch",
            display: { xs: "none", md: "flex" },
            mr: 1,
          }}
        />
      </Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} color="primary" />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const data = [
    {
      id: 1,
      date_test: "25/11/2023",
      nom_test: "JAVA test",
      nombre_critere: 15,
    },
    {
      id: 2,
      date_test: "11/12/2023",
      nom_test: "C# test",
      nombre_critere: 6,
    },
    {
      id: 3,
      date_test: "02/11/2023",
      nom_test: "Leadership test",
      nombre_critere: 4,
    },
    {
      id: 4,
      date_test: "10/12/2023",
      nom_test: "Communication test",
      nombre_critere: 13,
    },
    {
      id: 5,
      date_test: "10/12/2023",
      nom_test: "JAVA test",
      nombre_critere: 8,
    },
    {
      id: 6,
      date_test: "10/12/2023",
      nom_test: "JAVA test",
      nombre_critere: 7,
    },
    {
      id: 7,
      date_test: "10/07/2023",
      nom_test: "JAVA test",
      nombre_critere: 8,
    },
  ];

  const [date_compare, setDate_test] = React.useState("");
  const [date_compare1, setDate_test1] = React.useState("");
  const affichage = () => {
    if (date_compare < date_compare1) {
      console.log("date de compare inferieur a date compare");
    } else console.log("date de compare superieur ou egal a date compare");
  };
  const container =
    window !== undefined ? () => window().document.body : undefined;
  const date = new Date();
  const formattedDate = new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  }).format(date);
  console.log(date);
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
          >
            <img
              src="image/logo3.png"
              alt=""
              style={{
                width: "7ch",
                display: { xs: "none", md: "flex" },
                mr: 1,
              }}
            />
          </Typography>
          <Box sx={{ display: { xs: "none", sm: "block" } }}>
            {navItems.map((item) => (
              <Button key={item} sx={{ color: "#fff" }}>
                {item}
              </Button>
            ))}
          </Box>
        </Toolbar>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </nav>
      <Box component="main" sx={{ p: 3 }}>
        <Toolbar />
        <Typography variant="body2" textAlign="center">
          Test de performance
        </Typography>

        <Grid container spacing={3}>
          {data.map((item) => (
            <Grid key={item.id} item lg={3} xs={12}>
              <Card sx={{ maxWidth: 275 }}>
                <CardContent>
                  <Typography variant="h5">Test: {item.nom_test} </Typography>
                  <Typography>
                    Nombre de critère: {item.nombre_critere}
                  </Typography>
                  <Typography>Date du test: {item.date_test}</Typography>
                </CardContent>
                <CardActions>
                  {item.date_test < formattedDate ? (
                    <Button variant="contained">Faire test</Button>
                  ) : (
                    <Button disabled variant="contained">
                      Faire test
                    </Button>
                  )}
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
        <input
          type="date"
          value={date_compare}
          onChange={(e) => setDate_test(e.target.value)}
        />
        <input
          type="date"
          value={date_compare1}
          onChange={(e) => setDate_test1(e.target.value)}
        />

        {date_compare < formattedDate ? (
          <Typography>
            date aujourd hui superieur date que vous entrer
          </Typography>
        ) : (
          <Typography>
            date aujourd hui inferieur date que vous entrer
          </Typography>
        )}
        <Button onClick={() => affichage()}>Tester</Button>
      </Box>
    </Box>
  );
}

TestFront.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default TestFront;
