import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import GroupIcon from "@mui/icons-material/Group";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TopicIcon from "@mui/icons-material/Topic";
import DashboardIcon from "@mui/icons-material/Dashboard";
import QuizIcon from "@mui/icons-material/Quiz";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid } from "@mui/x-data-grid";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function TestManagement() {
  const [openAdd, setOpenAdd] = React.useState(false);
  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const [openEdit, setOpenedit] = React.useState(false);

  const handleClickOpenEdit = () => {
    setOpenedit(true);
  };
  const handleCloseEdit = () => {
    setOpenedit(false);
  };
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const column = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "nom_test", headerName: "Nom", width: 300 },
    { field: "date_test", headerName: "Date du test", width: 120 },
    { field: "critere", headerName: "Critère", width: 250 },
    { field: "departement", headerName: "Département", width: 150 },
    {
      field: "",
      width: 109,
      headerName: "Action",
      renderCell: () => (
        <>
          <IconButton
            onClick={handleClickOpenEdit}
            type="button"
            className="btn btn-danger"
            color="primary"
            size="small"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            type="button"
            className="btn btn-danger"
            color="error"
            size="small"
            onClick={handleOpenDialog}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];
  const rows = [
    {
      id: 2,
      nom_test: "Test ho ani rehetra",
      date_test: "DD/MM/YYYY",
      departement: "Informatique",
      critere: "critere1",
    },
    {
      id: 1,
      nom_test: "Test ho ani rehetra",
      date_test: "DD/MM/YYYY",
      departement: "Informatique",
      critere: "critere4",
    },
  ];

  const navigate = useNavigate("");
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar position="fixed">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <img src="image/logo3.png" style={{ width: 70 }} alt="entete-icon" />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/tableau-de-bord")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                primary="Tableau de bord"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/critere")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <TopicIcon />
              </ListItemIcon>
              <ListItemText primary="Critères" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/question")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <QuizIcon />
              </ListItemIcon>
              <ListItemText primary="Question" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/reponse")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <QuestionAnswerIcon />
              </ListItemIcon>
              <ListItemText
                primary="Historique des réponses"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/side-nav")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="Employé" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <TextSnippetIcon />
              </ListItemIcon>
              <ListItemText
                primary="Créer test"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List sx={{ paddingTop: 35 }}>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText
                primary="Se déconnecter"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Container>
        <Box paddingTop={10}>
          <Typography textAlign="center" variant="h5">
            Gestion des tests
          </Typography>
          <Button
            endIcon={<AddIcon />}
            variant="contained"
            color="success"
            onClick={handleClickOpenAdd}
            size="small"
          >
            Ajouter test
          </Button>
          <BootstrapDialog
            onClose={handleCloseAdd}
            aria-labelledby="customized-dialog-title"
            open={openAdd}
          >
            <DialogTitle
              align="center"
              sx={{ m: 0, p: 2 }}
              id="customized-dialog-title"
            >
              CREATION TEST
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseAdd}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Box display="flex" flexDirection="column" width={500}>
                <TextField
                  id="outlined-basic"
                  label="Nom du test"
                  variant="outlined"
                  size="small"
                />
                <FormControl fullWidth sx={{ marginTop: 1.5 }} size="small">
                  <Input type="date" label="date"></Input>
                </FormControl>
                <FormControl fullWidth sx={{ marginTop: 1.5 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Département
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Département"
                  >
                    <MenuItem value={10}>Informatique</MenuItem>
                    <MenuItem value={20}>Communication</MenuItem>
                    <MenuItem value={30}>Technique</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ marginTop: 1.5 }} size="small">
                  <InputLabel id="demo-simple-select-label">Critère</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Critère"
                  >
                    <MenuItem value={10}>Critère1</MenuItem>
                    <MenuItem value={20}>Critère2</MenuItem>
                    <MenuItem value={30}>Critère3</MenuItem>
                  </Select>
                </FormControl>
                <br />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseAdd} color="error">
                Annuler
              </Button>
              <Button autoFocus onClick={handleCloseAdd} color="success">
                Valider
              </Button>
            </DialogActions>
          </BootstrapDialog>
          <BootstrapDialog
            onClose={handleCloseEdit}
            aria-labelledby="customized-dialog-title"
            open={openEdit}
          >
            <DialogTitle
              align="center"
              sx={{ m: 0, p: 2 }}
              id="customized-dialog-title"
            >
              MODIFICATION TEST
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseEdit}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Box display="flex" flexDirection="column" width={500}>
                <TextField
                  id="outlined-basic"
                  label="Nom du test"
                  variant="outlined"
                  size="small"
                />
                <FormControl fullWidth sx={{ marginTop: 1.5 }} size="small">
                  <Input type="date" label="date"></Input>
                </FormControl>
                <FormControl fullWidth sx={{ marginTop: 1.5 }} size="small">
                  <InputLabel id="demo-simple-select-label">
                    Département
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Département"
                  >
                    <MenuItem value={10}>Informatique</MenuItem>
                    <MenuItem value={20}>Communication</MenuItem>
                    <MenuItem value={30}>Technique</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ marginTop: 1.5 }} size="small">
                  <InputLabel id="demo-simple-select-label">Critère</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Critère"
                  >
                    <MenuItem value={10}>Critère1</MenuItem>
                    <MenuItem value={20}>Critère2</MenuItem>
                    <MenuItem value={30}>Critère3</MenuItem>
                  </Select>
                </FormControl>
                <br />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseEdit} color="error">
                Annuler
              </Button>
              <Button autoFocus onClick={handleCloseEdit} color="success">
                Valider
              </Button>
            </DialogActions>
          </BootstrapDialog>
          <Dialog
            fullScreen={fullScreen}
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Validation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Voulez vous vraiment supprimer cette information?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseDialog} color="error">
                Annuler
              </Button>
              <Button onClick={handleCloseDialog} autoFocus>
                Oui
              </Button>
            </DialogActions>
          </Dialog>
          <DataGrid
            rows={rows}
            columns={column}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            sx={{ marginTop: 2.5 }}
          />
        </Box>
      </Container>
    </Box>
  );
}
