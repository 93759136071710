import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./login/js/login";
import Registration from "./login/js/inscription_responsable";
import LandingPage from "./landing_page/js/landing_page";
import SideNav from "./sidenav/js/sidenav";
import EmployeeManagement from "./employee/js/employee-management";
import CritereManagement from "./critere/js/critere_management";
import QuestionManagement from "./question/js/question_management";
import PageAjoutQuestion from "./question/js/page-ajout-question";
import PerformanceManagement from "./performance/js/performance_management";
import TableauDeBord from "./dashboard/js/tableau_de_bord";
import TestManagement from "./test/js/test_management";
import ReponseManagement from "./reponse/js/reponse_management";
import FrontOffice from "./front-office/js/front-office";
import TestFront from "./front-office/js/test-frontoffice";
import PasserTest from "./front-office/js/passer_test";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/registration" element={<Registration />}></Route>
        <Route path="/" element={<LandingPage />}></Route>
        <Route path="/side-nav" element={<SideNav />}></Route>
        <Route path="/employee" element={<EmployeeManagement />}></Route>
        <Route path="/critere" element={<CritereManagement />}></Route>
        <Route path="/question" element={<QuestionManagement />}></Route>
        <Route path="/tableau-de-bord" element={<TableauDeBord />}></Route>
        <Route path="/front-office" element={<FrontOffice />}></Route>
        <Route
          path="/page_ajout_question"
          element={<PageAjoutQuestion />}
        ></Route>
        <Route path="/performance" element={<PerformanceManagement />}></Route>
        <Route path="/test" element={<TestManagement />}></Route>
        <Route path="/reponse" element={<ReponseManagement />}></Route>
        <Route path="/front-test" element={<TestFront />}></Route>
        <Route path="/passer-test" element={<PasserTest />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
