import {
  Box,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import VisibilityIcon from "@mui/icons-material/Visibility";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { DataGrid } from "@mui/x-data-grid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const EmployeeManagement = () => {
  const notify = () =>
    toast.success("Insertion avec success!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const notifyDelete = () => {
    toast.error("Deleted with successfuly !", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };
  const notifyEdit = () =>
    toast.success("Modification avec success!", {
      position: "bottom-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  const column = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "nom", headerName: "Nom", width: 200 },
    { field: "prenom", headerName: "Prénom", width: 70 },
    { field: "departement", headerName: "Département", width: 120 },
    { field: "poste", headerName: "Poste", width: 120 },
    { field: "cin", headerName: "CIN", width: 115 },
    { field: "matricule", headerName: "Matricule", width: 70 },
    { field: "sexe", headerName: "Sexe", width: 70 },
    { field: "contact", headerName: "Contact", width: 100 },
    { field: "email", headerName: "Email", width: 100 },
    {
      field: "",
      width: 109,
      headerName: "Action",
      renderCell: () => (
        <>
          <IconButton
            onClick={handleClickOpenEdit}
            type="button"
            className="btn btn-danger"
            color="primary"
            size="small"
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={handleClickOpen}
            type="button"
            className="btn btn-danger"
            color="error"
            size="small"
          >
            <DeleteIcon />
          </IconButton>
          <IconButton type="button" className="btn btn-danger" size="small">
            <VisibilityIcon />
          </IconButton>
        </>
      ),
    },
  ];
  const rows = [
    {
      id: 1,
      nom: "RAZAKANDRIANTSOA",
      prenom: "Antsa",
      departement: "Informatique",
      poste: "Développeur",
      cin: "101221122943",
      matricule: "854H-F",
      sexe: "Homme",
      contact: "0345534014",
      email: "antsa@gmail.com",
    },
    {
      id: 2,
      nom: "SOLO",
      prenom: "Paika",
      departement: "Communication",
      poste: "rh",
      cin: "101221122943",
      matricule: "852H-F",
      sexe: "Femme",
      contact: "0345534414",
      email: "solo@gmail.com",
    },
    {
      id: 3,
      nom: "RAZANATAPONY",
      prenom: "Havoana",
      departement: "Fertile",
      poste: "rh",
      cin: "101221122443",
      matricule: "812H-F",
      sexe: "Femme",
      contact: "0345534414",
      email: "tapo@gmail.com",
    },
    {
      id: 4,
      nom: "RAFANOMEZANA",
      prenom: "Hyper",
      departement: "Communication",
      poste: "rh",
      cin: "101221122943",
      matricule: "852H-F",
      sexe: "Femme",
      contact: "0345534414",
      email: "solo@gmail.com",
    },
    {
      id: 5,
      nom: "RAKOTO",
      prenom: "Nandra",
      departement: "Communication",
      poste: "rh",
      cin: "101221122943",
      matricule: "852H-F",
      sexe: "Femme",
      contact: "0345534414",
      email: "solo@gmail.com",
    },
    {
      id: 6,
      nom: "RASOLOFO",
      prenom: "Game",
      departement: "Bureautique",
      poste: "rh",
      cin: "101221122943",
      matricule: "852H-F",
      sexe: "Homme",
      contact: "0345534414",
      email: "solo@gmail.com",
    },
  ];
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const [openEdit, setOpenedit] = React.useState(false);

  const handleClickOpenEdit = () => {
    setOpenedit(true);
  };
  const handleCloseEdit = () => {
    setOpenedit(false);
  };
  const [openAdd, setOpenAdd] = React.useState(false);

  const handleClickOpenAdd = () => {
    setOpenAdd(true);
  };
  const handleCloseAdd = () => {
    setOpenAdd(false);
  };
  const [departement, setDepartement] = React.useState("");
  const handleChange = (event) => {
    setDepartement(event.target.value);
  };
  return (
    <div>
      <Box>
        <Typography variant="h5" textAlign="center">
          GESTIONS DES EMPLOYEES
        </Typography>
        <Grid container>
          <Grid item xs={2} marginTop={1.5}>
            <Button
              variant="contained"
              size="small"
              startIcon={<InsertDriveFileIcon />}
            >
              Importer fichier
            </Button>
          </Grid>
          <Grid xs={2} item marginTop={1.5}>
            <Button
              onClick={handleClickOpenAdd}
              variant="contained"
              color="success"
              startIcon={<AddIcon />}
              size="small"
            >
              Ajouter
            </Button>
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <TextField
              label="Recherche par nom"
              id="outlined-start-adornment"
              size="small"
              sx={{ m: 1, width: "35ch" }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />

        <DataGrid
          rows={rows}
          columns={column}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10]}
          checkboxSelection
        />
        <Dialog
          fullScreen={fullScreen}
          open={open}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Validation"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Voulez vous vraiment supprimer cette information?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="error">
              Annuler
            </Button>
            <Button onClick={notifyDelete} autoFocus>
              Oui
            </Button>
          </DialogActions>
        </Dialog>
        <BootstrapDialog
          onClose={handleCloseEdit}
          aria-labelledby="customized-dialog-title"
          open={openEdit}
        >
          <DialogTitle
            align="center"
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
          >
            MODIFICATION EMPLOYE
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseEdit}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Box display="flex" flexDirection="column" width={500}>
              <TextField
                id="outlined-basic"
                label="Nom"
                variant="outlined"
                size="small"
              />
              <TextField
                sx={{ marginTop: 1.5 }}
                id="outlined-basic"
                label="Prénom"
                variant="outlined"
                size="small"
              />
              <FormControl fullWidth sx={{ marginTop: 1.5 }} size="small">
                <InputLabel id="demo-simple-select-label">
                  Département
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={departement}
                  label="Département"
                  onChange={handleChange}
                >
                  <MenuItem value={10}>Informatique</MenuItem>
                  <MenuItem value={20}>Communication</MenuItem>
                  <MenuItem value={30}>Technique</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{ marginTop: 1.5 }}
                id="outlined-basic"
                label="Poste"
                variant="outlined"
                size="small"
              />
              <TextField
                sx={{ marginTop: 1.5 }}
                id="outlined-basic"
                label="Cin"
                variant="outlined"
                size="small"
              />
              <TextField
                id="outlined-basic"
                label="Matricule"
                variant="outlined"
                sx={{ marginTop: 1.5 }}
                size="small"
              />
              <FormControl size="small" sx={{ marginTop: 1.5 }}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Sexe
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="homme"
                    control={<Radio size="small" />}
                    label="Homme"
                  />
                  <FormControlLabel
                    value="femme"
                    control={<Radio size="small" />}
                    label="Femme"
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                id="outlined-basic"
                label="Contact"
                variant="outlined"
                size="small"
                sx={{ marginTop: 1.5 }}
              />
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                sx={{ marginTop: 1.5 }}
                size="small"
              />
              <br />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="error">
              Annuler
            </Button>
            <Button autoFocus onClick={notifyEdit} color="success">
              Valider
            </Button>
          </DialogActions>
        </BootstrapDialog>
        <BootstrapDialog
          onClose={handleCloseEdit}
          aria-labelledby="customized-dialog-title"
          open={openAdd}
        >
          <DialogTitle
            align="center"
            sx={{ m: 0, p: 2 }}
            id="customized-dialog-title"
          >
            AJOUT EMPLOYEE
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleCloseAdd}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent dividers>
            <Box display="flex" flexDirection="column" width={500}>
              <TextField
                id="outlined-basic"
                label="Nom"
                variant="outlined"
                size="small"
              />
              <TextField
                sx={{ marginTop: 1.5 }}
                id="outlined-basic"
                label="Prénom"
                variant="outlined"
                size="small"
              />
              <FormControl size="small" fullWidth sx={{ marginTop: 1.5 }}>
                <InputLabel id="demo-simple-select-label">
                  Département
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={departement}
                  label="Département"
                  onChange={handleChange}
                >
                  <MenuItem value={10}>Informatique</MenuItem>
                  <MenuItem value={20}>Communication</MenuItem>
                  <MenuItem value={30}>Technique</MenuItem>
                </Select>
              </FormControl>
              <TextField
                sx={{ marginTop: 1.5 }}
                id="outlined-basic"
                label="Poste"
                variant="outlined"
                size="small"
              />
              <TextField
                sx={{ marginTop: 1.5 }}
                id="outlined-basic"
                label="Cin"
                variant="outlined"
                size="small"
              />
              <TextField
                id="outlined-basic"
                label="Matricule"
                variant="outlined"
                sx={{ marginTop: 1.5 }}
                size="small"
              />
              <FormControl sx={{ marginTop: 1.5 }}>
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Sexe
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="homme"
                    control={<Radio size="small" />}
                    label="Homme"
                  />
                  <FormControlLabel
                    value="femme"
                    control={<Radio size="small" />}
                    label="Femme"
                  />
                </RadioGroup>
              </FormControl>
              <TextField
                id="outlined-basic"
                label="Contact"
                variant="outlined"
                sx={{ marginTop: 1.5 }}
                size="small"
              />
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                sx={{ marginTop: 1.5 }}
                size="small"
              />
              <br />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseAdd} color="error">
              Annuler
            </Button>
            <Button autoFocus onClick={notify} color="success">
              Ajouter
            </Button>
          </DialogActions>
        </BootstrapDialog>
      </Box>
    </div>
  );
};

export default EmployeeManagement;
