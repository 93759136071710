import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import GroupIcon from "@mui/icons-material/Group";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import TopicIcon from "@mui/icons-material/Topic";
import AddIcon from "@mui/icons-material/Add";
import DashboardIcon from "@mui/icons-material/Dashboard";
import QuizIcon from "@mui/icons-material/Quiz";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Topic";
import CloseIcon from "@mui/icons-material/Close";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));
const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function SideNav() {
  const [openDialog, setOpenDialog] = React.useState(false);
  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const [openEdit, setOpenEdit] = React.useState(false);

  const handleOpenAdd = () => {
    setOpenEdit(true);
  };

  const handleCloseAdd = () => {
    setOpenEdit(false);
  };

  const navigate = useNavigate("");
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar position="fixed">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <img src="image/logo3.png" style={{ width: 70 }} alt="entete-icon" />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/tableau-de-bord")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <DashboardIcon />
              </ListItemIcon>
              <ListItemText
                primary="Tableau de bord"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/critere")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <TopicIcon />
              </ListItemIcon>
              <ListItemText primary="Critères" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/question")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <QuizIcon />
              </ListItemIcon>
              <ListItemText primary="Question" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/reponse")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <QuestionAnswerIcon />
              </ListItemIcon>
              <ListItemText
                primary="Historique des réponses"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => navigate("/side-nav")}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <GroupIcon />
              </ListItemIcon>
              <ListItemText primary="Employé" sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <TextSnippetIcon />
              </ListItemIcon>
              <ListItemText
                primary="Créer test"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
        <Divider />
        <List sx={{ paddingTop: 35 }}>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={handleClickOpenDialog}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText
                primary="Se déconnecter"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Container marginTop={2}>
          <Button
            variant="contained"
            endIcon={<AddIcon />}
            onClick={handleOpenAdd}
          >
            Ajout critére
          </Button>
          <Grid container spacing={1.25} sx={{ marginTop: 1.5 }}>
            <Grid item>
              <Card sx={{ width: 275 }}>
                <CardContent>
                  <Typography variant="h5" textAlign="center">
                    LEADERSHIP
                  </Typography>
                  <Typography component="div" sx={{ fontSize: 14 }}>
                    <br />
                    Durée: 2h
                    <br />
                    Nombre de question: 10
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Nom departement: Informatique
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    content
                    size="small"
                    endIcon={<EditIcon />}
                    variant="contained"
                  >
                    Modifier
                  </Button>
                  <Button
                    content
                    size="small"
                    endIcon={<DeleteIcon />}
                    variant="contained"
                    color="error"
                    //action suppression d'un critére onClick
                    onClick={handleClickOpenDialog}
                  >
                    supprimer
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item>
              <Card sx={{ width: 275 }}>
                <CardContent>
                  <Typography variant="h5" textAlign="center">
                    COMMUNICATION
                  </Typography>
                  <Typography component="div" sx={{ fontSize: 14 }}>
                    <br />
                    Durée: 2h
                    <br />
                    Nombre de question: 10
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    Nom departement: Informatique
                  </Typography>
                </CardContent>
                <CardActions>
                  <Button
                    content
                    size="small"
                    endIcon={<EditIcon />}
                    variant="contained"
                  >
                    Modifier
                  </Button>
                  <Button
                    content
                    size="small"
                    endIcon={<DeleteIcon />}
                    variant="contained"
                    color="error"
                    //action suppression d'un critére onClick
                    onClick={handleClickOpenDialog}
                  >
                    supprimer
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
          <BootstrapDialog
            onClose={handleCloseAdd}
            aria-labelledby="customized-dialog-title"
            open={openEdit}
          >
            <DialogTitle
              align="center"
              sx={{ m: 0, p: 2 }}
              id="customized-dialog-title"
            >
              AJOUT CRITERE
            </DialogTitle>
            <IconButton
              aria-label="close"
              onClick={handleCloseAdd}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Box display="flex" flexDirection="column" width={500}>
                <TextField
                  id="outlined-basic"
                  label="Titre"
                  variant="outlined"
                  size="small"
                />
                <TextField
                  sx={{ marginTop: 1.5 }}
                  id="outlined-basic"
                  label="Nombre de question"
                  variant="outlined"
                  size="small"
                />
                <br />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseAdd} color="error">
                Annuler
              </Button>
              <Button autoFocus onClick={handleCloseAdd} color="success">
                Ajouter
              </Button>
            </DialogActions>
          </BootstrapDialog>
          <Dialog
            fullScreen={fullScreen}
            open={openDialog}
            onClose={handleCloseDialog}
            aria-labelledby="responsive-dialog-title"
          >
            <DialogTitle id="responsive-dialog-title">
              {"Validation"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                Voulez vous vraiment supprimer cette critére?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleCloseDialog} color="error">
                Annuler
              </Button>
              <Button onClick={handleCloseDialog} autoFocus>
                Oui
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
        <Dialog
          fullScreen={fullScreen}
          open={openDialog}
          onClose={handleCloseDialog}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">{"Validation"}</DialogTitle>
          <DialogContent>
            <DialogContentText>Voulez vous se déconnecter?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleCloseDialog} color="error">
              Annuler
            </Button>
            <Button onClick={(e) => navigate("/")} autoFocus>
              Oui
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}
